import { formatISO, parseISO } from "date-fns";

import type { OpenShift, OpenShiftWorkplace } from "../Shift/OpenShifts/types";
import type { UserFilters } from "./types";

interface FilterOpenShiftsProps {
  shifts: OpenShift[];
  workplacesMap: Map<string, OpenShiftWorkplace>;
}

export function filterOpenShifts(
  props: FilterOpenShiftsProps,
  filters: Omit<UserFilters, "distance" | "license">
) {
  const { shifts, workplacesMap } = props;
  const { dates, workplaceRating, shiftBookingType, shiftTimeSlot } = filters;

  if (shifts.length === 0) {
    return [];
  }

  return shifts.filter((shift) => {
    const shiftDate = formatISO(parseISO(shift.attributes.start), { representation: "date" });

    if (dates.length > 0 && !dates.includes(shiftDate)) {
      return false;
    }

    if (shiftTimeSlot && shift.attributes.timeSlot !== shiftTimeSlot) {
      return false;
    }

    const workplace = workplacesMap.get(shift.relationships.workplace.data.id);
    if (workplaceRating && (workplace?.attributes.rating.value ?? 0) < workplaceRating) {
      return false;
    }

    if (shiftBookingType) {
      // TODO: handle block booking filtering after it is implemented
      // https://linear.app/clipboardhealth/issue/SPRTA-813/display-blocks-together-with-other-shifts
    }

    return true;
  });
}
