import { Text } from "@clipboard-health/ui-react";
import { Stack, ToggleButton } from "@mui/material";

import { TimeSlot } from "../../Shift/OpenShifts/types";
import { TimeSlotIcon } from "../../Shift/TimeSlotIcon";
import { FiltersToggleButtonGroup } from "./ToggleButtonGroup";
import { FILTER_VALUE_ALL, type FiltersFormValues } from "./types";

interface ShiftTimeSlotFilterProps {
  value?: FiltersFormValues["shiftTimeSlot"];
  onChange: (value: FiltersFormValues["shiftTimeSlot"]) => void;
}

export function ShiftTimeSlotFilter(props: ShiftTimeSlotFilterProps) {
  const { value, onChange } = props;

  return (
    <Stack spacing={5}>
      <Text semibold variant="body1">
        Shift Times
      </Text>

      <FiltersToggleButtonGroup
        value={value}
        onChange={(value) => {
          onChange(value ?? FILTER_VALUE_ALL);
        }}
      >
        <ToggleButton value={FILTER_VALUE_ALL}>All</ToggleButton>
        <ToggleButton value={TimeSlot.AM}>
          <TimeSlotIcon timeSlot={TimeSlot.AM} />
          Day
        </ToggleButton>
        <ToggleButton value={TimeSlot.PM}>
          <TimeSlotIcon timeSlot={TimeSlot.PM} />
          Evening
        </ToggleButton>
        <ToggleButton value={TimeSlot.NOC}>
          <TimeSlotIcon timeSlot={TimeSlot.NOC} />
          Overnight
        </ToggleButton>
      </FiltersToggleButtonGroup>
    </Stack>
  );
}
