import { useListViewOpenShiftsData } from "../../ListView/useListViewOpenShiftsData";
import { filterOpenShifts } from "../filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../useUserFiltersContext";
import { formValuesToUserFilters } from "./formValuesToUserFilters";
import type { FiltersFormValues } from "./types";

export function useFilteredOpenShiftsCount(formValues: FiltersFormValues) {
  const { dates } = useShiftDiscoveryUserFiltersContext();

  const localUserFilters = formValuesToUserFilters(formValues);

  const {
    shifts = [],
    workplacesMap = new Map(),
    isLoading,
  } = useListViewOpenShiftsData({
    distance: localUserFilters.distance,
    license: localUserFilters.license,
  });

  const filteredOpenShiftsData = filterOpenShifts(
    { shifts, workplacesMap },
    {
      dates,
      ...localUserFilters,
    }
  );

  return {
    count: filteredOpenShiftsData.length,
    isLoading,
  };
}
