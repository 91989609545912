import { Text } from "@clipboard-health/ui-react";
import { Stack, ToggleButton } from "@mui/material";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";
import { ShiftBookingType } from "@src/appV2/ShiftBlocks/utils";

import { FiltersToggleButtonGroup } from "./ToggleButtonGroup";
import { FILTER_VALUE_ALL, type FiltersFormValues } from "./types";

export function useIsShiftBookingTypeFilterEnabled() {
  const shiftBlockBookingFlag = useCbhFlag(CbhFeatureFlag.ROLLOUT_SHIFT_BLOCK_BOOKING, {
    defaultValue: {
      isBlockBookingEnabled: false,
    },
  });
  return shiftBlockBookingFlag.isBlockBookingEnabled;
}

interface ShiftBookingTypeFilterProps {
  value?: FiltersFormValues["shiftBookingType"];
  onChange: (value: FiltersFormValues["shiftBookingType"]) => void;
}

export function ShiftBookingTypeFilter(props: ShiftBookingTypeFilterProps) {
  const { value, onChange } = props;

  return (
    <Stack spacing={5}>
      <Text semibold variant="body1">
        Shift Types
      </Text>

      <FiltersToggleButtonGroup
        value={value}
        onChange={(value) => {
          onChange(value ?? FILTER_VALUE_ALL);
        }}
      >
        <ToggleButton value={FILTER_VALUE_ALL}>All</ToggleButton>
        <ToggleButton value={ShiftBookingType.PER_DIEM}>Per Diem</ToggleButton>
        <ToggleButton value={ShiftBookingType.BLOCK_BOOKING}>Blocks</ToggleButton>
      </FiltersToggleButtonGroup>
    </Stack>
  );
}
