import type { ShiftBookingType } from "../../ShiftBlocks/utils";
import type { TimeSlot } from "../Shift/OpenShifts/types";

export type ShiftBookingTypeFilterOption =
  | ShiftBookingType.PER_DIEM
  | ShiftBookingType.BLOCK_BOOKING;

export enum WorkplaceRatingFilterOption {
  THREE_STARS_AND_UP = 3,
  FOUR_STARS_AND_UP = 4,
  // TODO: handle favorite filter after it's implemented
  FAVORITE = 5,
}

export type ShiftTimeSlotFilterOption = TimeSlot;

export interface StoredUserFilters {
  shiftBookingType?: ShiftBookingTypeFilterOption;
  shiftTimeSlot?: ShiftTimeSlotFilterOption;
  workplaceRating?: WorkplaceRatingFilterOption;
}

export interface UserFilters extends StoredUserFilters {
  dates: string[];
  distance: number;
  license: string;
}
