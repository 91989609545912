import { Text } from "@clipboard-health/ui-react";
import { Stack, ToggleButton } from "@mui/material";
import { uniq } from "lodash";

import { FiltersToggleButtonGroup } from "./ToggleButtonGroup";
import { type FiltersFormValues } from "./types";

interface LicensesFilterProps {
  value?: FiltersFormValues["license"];
  onChange: (value: FiltersFormValues["license"]) => void;
  options: string[];
}

export function LicensesFilter(props: LicensesFilterProps) {
  const { value, onChange, options } = props;

  // There might be multiple licenses with the same name but different states
  // https://github.com/ClipboardHealth/cbh-mobile-app/pull/3849
  const licenses = uniq(options).sort();

  return (
    <Stack spacing={5}>
      <Text semibold variant="body1">
        Licenses
      </Text>

      <FiltersToggleButtonGroup
        value={value}
        onChange={(value) => {
          if (value) {
            onChange(value);
          }
        }}
      >
        {/*
          FIXME: We can't actually filter by 'All' licenses yet, because the
          backend doesn't support it. It always seem to filter shifts by the
          agent's qualification. We need to update the backend to support this.
          <ToggleButton value={FILTER_VALUE_ALL}>All</ToggleButton>
        */}
        {licenses.map((license) => (
          <ToggleButton key={license} value={license}>
            {license}
          </ToggleButton>
        ))}
      </FiltersToggleButtonGroup>
    </Stack>
  );
}
