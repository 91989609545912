import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Divider, Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { BottomSheet } from "../../components/BottomSheet";
import { Callout } from "../../components/Callout";
import { CounterText } from "../../components/CounterText";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { DistanceFilter } from "./DistanceFilter";
import { FiltersBottomSheetFooter } from "./Footer";
import { LicensesFilter } from "./LicensesFilter";
import {
  ShiftBookingTypeFilter,
  useIsShiftBookingTypeFilterEnabled,
} from "./ShiftBookingTypeFilter";
import { ShiftTimeSlotFilter } from "./ShiftTimeSlotFilter";
import { useFilteredOpenShiftsCount } from "./useFilteredOpenShiftsCount";
import { useFiltersForm } from "./useFiltersForm";
import { WorkplaceRatingFilter } from "./WorkplaceRatingFilter";

interface ShiftDiscoveryFiltersBottomSheetProps {
  modalState: UseModalState;
}

export function ShiftDiscoveryFiltersBottomSheet(props: ShiftDiscoveryFiltersBottomSheetProps) {
  const { modalState } = props;

  const history = useHistory();

  const worker = useDefinedWorker();
  const workerLicenses = worker?.licensesData?.map((license) => license.qualification) ?? [];

  const { form, changedFiltersCount, resetFilters, cancelFilters, submitFilters } =
    useFiltersForm();

  const formValues = form.watch();

  const { count: filteredOpenShiftsCount, isLoading: filteredOpenShiftsIsLoading } =
    useFilteredOpenShiftsCount(formValues);

  const isShiftBookingTypeFilterEnabled = useIsShiftBookingTypeFilterEnabled();

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <FiltersBottomSheetFooter
          showClearFormButton={changedFiltersCount > 0}
          openShiftsCount={filteredOpenShiftsCount}
          openShiftsIsLoading={filteredOpenShiftsIsLoading}
          onClear={resetFilters}
          onCancel={() => {
            cancelFilters();
            modalState.closeModal();
          }}
          onSubmit={() => {
            submitFilters();
            modalState.closeModal();
          }}
        />
      }
    >
      <DialogContent sx={{ paddingBottom: 12 }}>
        <Stack spacing={8}>
          <PageHeaderWrapper variant="tertiary">
            <CounterText count={changedFiltersCount} suffix="applied">
              <Title variant="h4" component="h4">
                Filters
              </Title>
            </CounterText>
          </PageHeaderWrapper>

          {isShiftBookingTypeFilterEnabled && (
            <Controller
              control={form.control}
              name="shiftBookingType"
              render={({ field }) => (
                <ShiftBookingTypeFilter value={field.value} onChange={field.onChange} />
              )}
            />
          )}

          <Divider />

          <Controller
            control={form.control}
            name="shiftTimeSlot"
            render={({ field }) => (
              <ShiftTimeSlotFilter value={field.value} onChange={field.onChange} />
            )}
          />

          <Divider />

          <Stack spacing={5}>
            <Controller
              control={form.control}
              name="distance"
              render={({ field }) => (
                <DistanceFilter value={field.value} onChange={field.onChange} />
              )}
            />

            <Callout
              title="Traveling?"
              text="Explore shifts in more states"
              iconType="suitcase"
              sx={(theme) => ({
                backgroundColor: theme.palette.callout?.neutral.labelDark,
                color: theme.palette.callout?.neutral.text,
              })}
              onClick={() => {
                history.push(`${RootPaths.HOME}/account/searchAddressLocation`);
              }}
            />
          </Stack>

          <Divider />

          <Controller
            control={form.control}
            name="license"
            render={({ field }) => (
              <LicensesFilter
                value={field.value}
                options={workerLicenses}
                onChange={field.onChange}
              />
            )}
          />

          <Divider />

          <Controller
            control={form.control}
            name="workplaceRating"
            render={({ field }) => (
              <WorkplaceRatingFilter value={field.value} onChange={field.onChange} />
            )}
          />
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
