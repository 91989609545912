import { useForm } from "react-hook-form";

import { useShiftDiscoveryUserFiltersContext } from "../useUserFiltersContext";
import { formValuesToUserFilters } from "./formValuesToUserFilters";
import { FILTER_VALUE_ALL, type FiltersFormValues } from "./types";

function getDefaultFormValues(defaultValues: FiltersFormValues): FiltersFormValues {
  return {
    shiftBookingType: FILTER_VALUE_ALL,
    shiftTimeSlot: FILTER_VALUE_ALL,
    workplaceRating: FILTER_VALUE_ALL,
    ...defaultValues,
  };
}

export function useFiltersForm() {
  const { setFilters, shiftBookingType, shiftTimeSlot, distance, license, workplaceRating } =
    useShiftDiscoveryUserFiltersContext();

  const defaultFormValues = getDefaultFormValues({
    distance,
    license,
  });

  const form = useForm<FiltersFormValues>({
    defaultValues: {
      ...defaultFormValues,
      shiftBookingType: shiftBookingType ?? defaultFormValues.shiftBookingType,
      shiftTimeSlot: shiftTimeSlot ?? defaultFormValues.shiftTimeSlot,
      workplaceRating: workplaceRating ?? defaultFormValues.workplaceRating,
    },
  });

  const formValues = form.watch();
  const changedFiltersCount = Object.entries(formValues).filter(
    ([key, value]) => value !== defaultFormValues[key as keyof FiltersFormValues]
  ).length;

  const resetFilters = () => {
    form.reset(defaultFormValues);
    setFilters(formValuesToUserFilters(defaultFormValues));
  };

  const cancelFilters = () => {
    form.reset(); // resets to values when form was initialized
  };

  const submitFilters = () => {
    setFilters(formValuesToUserFilters(formValues));
  };

  return { form, changedFiltersCount, resetFilters, cancelFilters, submitFilters };
}
