import { Box } from "@mui/material";

import { Badge } from "../components/Badge";
import type { TimeSlot } from "../Shift/OpenShifts/types";
import { TimeSlotIcon } from "../Shift/TimeSlotIcon";

interface BookedShiftsIconRowProps {
  bookedShiftTimeSlots: TimeSlot[];
  maxShiftIconsCountToShow: number;
  disabled?: boolean;
}

export function BookedShiftsIconRow(props: BookedShiftsIconRowProps) {
  const { bookedShiftTimeSlots, maxShiftIconsCountToShow, disabled } = props;

  if (bookedShiftTimeSlots.length === 0) {
    return null;
  }

  if (bookedShiftTimeSlots.length > maxShiftIconsCountToShow) {
    return <Badge color="success" count={bookedShiftTimeSlots.length} size="small" />;
  }

  return (
    <Box
      sx={(theme) => ({
        color: disabled ? theme.palette.disabled?.primary : theme.palette.success.main,
        display: "flex",
      })}
    >
      {bookedShiftTimeSlots.map((timeSlot) => (
        <TimeSlotIcon key={timeSlot} timeSlot={timeSlot} size="small" />
      ))}
    </Box>
  );
}
