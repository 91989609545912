import { type UserFilters } from "../types";
import { FILTER_VALUE_ALL, type FiltersFormValues, type FilterValueAll } from "./types";

function valueOrUndefined<V>(value: V | FilterValueAll | undefined): V | undefined {
  return value === FILTER_VALUE_ALL ? undefined : value;
}

export function formValuesToUserFilters(formValues: FiltersFormValues): Omit<UserFilters, "dates"> {
  return {
    distance: formValues.distance,
    license: formValues.license,
    shiftBookingType: valueOrUndefined(formValues.shiftBookingType),
    shiftTimeSlot: valueOrUndefined(formValues.shiftTimeSlot),
    workplaceRating: valueOrUndefined(formValues.workplaceRating),
  };
}
