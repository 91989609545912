import { Text } from "@clipboard-health/ui-react";
import { type ComponentProps } from "react";

import { DotSeparatedList } from "../components/DotSeparatedList";
import { formatDayOfMonthWithDayName } from "../utils/formatDayOfMonthWithDayName";
import { formatHoursDurationCompact } from "../utils/formatHoursDurationCompact";

// TODO: Export TextProps from @clipboard-health/ui-react
export interface DateWithDurationProps extends ComponentProps<typeof Text> {
  startDate: Date;
  durationInHours: number;
}

export function DateWithDuration(props: DateWithDurationProps) {
  const { startDate, durationInHours, ...textProps } = props;

  return (
    <DotSeparatedList>
      <Text {...textProps}>{formatDayOfMonthWithDayName(startDate)}</Text>
      <Text {...textProps}>{formatHoursDurationCompact(durationInHours)}</Text>
    </DotSeparatedList>
  );
}
