import {
  type ShiftBookingTypeFilterOption,
  type ShiftTimeSlotFilterOption,
  type WorkplaceRatingFilterOption,
} from "../types";

export const FILTER_VALUE_ALL = "filter_all";

export type FilterValueAll = typeof FILTER_VALUE_ALL;

export interface FiltersFormValues {
  distance: number;
  license: string;
  shiftBookingType?: FilterValueAll | ShiftBookingTypeFilterOption;
  shiftTimeSlot?: FilterValueAll | ShiftTimeSlotFilterOption;
  workplaceRating?: FilterValueAll | WorkplaceRatingFilterOption;
}
