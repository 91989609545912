import { useLocalStorage } from "@src/appV2/lib/utils";
import { useCallback } from "react";

import { USER_FILTERS_LOCAL_STORAGE_KEY } from "./constants";
import type { StoredUserFilters } from "./types";

const defaultStoredFilters: StoredUserFilters = {
  workplaceRating: undefined,
  shiftBookingType: undefined,
  shiftTimeSlot: undefined,
};

export function useStoredUserFilters(initialFilters: StoredUserFilters = {}) {
  const [storedFilters, setStoredFilters] = useLocalStorage<StoredUserFilters>(
    USER_FILTERS_LOCAL_STORAGE_KEY,
    { ...defaultStoredFilters, ...initialFilters }
  );

  const clearStoredFilters = useCallback(() => {
    setStoredFilters(defaultStoredFilters);
  }, [setStoredFilters]);

  const setStoredFilter = useCallback(
    (key: keyof StoredUserFilters, value: StoredUserFilters[typeof key]) => {
      setStoredFilters((filters) => ({ ...filters, [key]: value }));
    },
    [setStoredFilters]
  );

  return {
    storedFilters,
    setStoredFilters,
    setStoredFilter,
    clearStoredFilters,
  };
}
