import { Stack } from "@mui/material";

import { DialogFooter } from "../../components/DialogFooter";
import { IconButton } from "../../components/IconButton";
import { LoadingButton } from "../../components/LoadingButton";
import { TextButton } from "../../components/TextButton";

interface FiltersBottomSheetFooterProps {
  showClearFormButton: boolean;
  openShiftsCount: number;
  openShiftsIsLoading: boolean;
  onClear: () => void;
  onSubmit: () => void;
  onCancel: () => void;
}

export function FiltersBottomSheetFooter(props: FiltersBottomSheetFooterProps) {
  const { showClearFormButton, openShiftsCount, openShiftsIsLoading, onClear, onCancel, onSubmit } =
    props;

  const openShiftsIsEmpty = openShiftsCount === 0 && !openShiftsIsLoading;

  return (
    <DialogFooter withShadow orientation="vertical" sx={{ gap: 7 }}>
      {showClearFormButton && (
        <TextButton size="medium" onClick={onClear}>
          Clear all filters
        </TextButton>
      )}

      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <IconButton size="large" variant="outlined" iconType="close" onClick={onCancel} />

        <LoadingButton
          isLoading={openShiftsIsLoading}
          variant="contained"
          size="large"
          onClick={onSubmit}
        >
          {openShiftsIsEmpty ? "No shifts available" : `Show ${openShiftsCount} shifts`}
        </LoadingButton>
      </Stack>
    </DialogFooter>
  );
}
