import { Text } from "@clipboard-health/ui-react";
import { Stack, ToggleButton } from "@mui/material";

import { WorkplaceRatingFilterOption } from "../types";
import { FiltersToggleButtonGroup } from "./ToggleButtonGroup";
import { FILTER_VALUE_ALL, type FiltersFormValues } from "./types";

interface WorkplaceRatingFilterProps {
  value?: FiltersFormValues["workplaceRating"];
  onChange: (value: FiltersFormValues["workplaceRating"]) => void;
}

export function WorkplaceRatingFilter(props: WorkplaceRatingFilterProps) {
  const { value, onChange } = props;

  return (
    <Stack spacing={5}>
      <Text semibold variant="body1">
        Facility
      </Text>

      <FiltersToggleButtonGroup
        value={value}
        onChange={(value) => {
          onChange(value ?? FILTER_VALUE_ALL);
        }}
      >
        <ToggleButton value={FILTER_VALUE_ALL}>All</ToggleButton>
        <ToggleButton value={WorkplaceRatingFilterOption.FOUR_STARS_AND_UP}>+4 stars</ToggleButton>
        <ToggleButton value={WorkplaceRatingFilterOption.THREE_STARS_AND_UP}>+3 stars</ToggleButton>
        <ToggleButton value={WorkplaceRatingFilterOption.FAVORITE}>Favorites Only</ToggleButton>
      </FiltersToggleButtonGroup>
    </Stack>
  );
}
