import { isDefined } from "@clipboard-health/util-ts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import constate from "constate";
import { useState } from "react";

import { useAgentPreferences } from "../../Agents/api/useAgentPreferences";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "./constants";
import {
  type ShiftBookingTypeFilterOption,
  type ShiftTimeSlotFilterOption,
  type UserFilters,
  type WorkplaceRatingFilterOption,
} from "./types";
import { useStoredUserFilters } from "./useStoredUserFilters";

interface UserFiltersContext extends UserFilters {
  appliedFiltersCount: number;
  clearDates: () => void;
  clearFilters: () => void;
  setDates: (dates: string[]) => void;
  setDistance: (value: number) => void;
  setFilters: (filters: Partial<UserFilters>) => void;
  setLicense: (value?: string) => void;
  setShiftBookingType: (value?: ShiftBookingTypeFilterOption) => void;
  setShiftTimeSlot: (value?: ShiftTimeSlotFilterOption) => void;
  setWorkplaceRating: (value?: WorkplaceRatingFilterOption) => void;
}

function useShiftDiscoveryUserFilters(): UserFiltersContext {
  const [dates, setDates] = useState<string[]>([]);

  const clearDates = () => {
    setDates([]);
  };

  const { filters: agentPreferences, setFilters: setAgentPreferences } = useAgentPreferences();
  const worker = useDefinedWorker();

  const { distance = DEFAULT_DISTANCE_IN_MILES_FILTER, license } = agentPreferences;

  const setDistance = (value: number) => {
    setAgentPreferences({ distance: value, license });
  };

  const setLicense = (value?: string) => {
    setAgentPreferences({ distance, license: value ?? "" });
  };

  // Dates don't need to be tracked in local storage as they change frequently.
  // Distance and license also don't need to be tracked in local storage because
  // they're already in the agent preferences.
  const { storedFilters, setStoredFilters, setStoredFilter, clearStoredFilters } =
    useStoredUserFilters();

  const setShiftBookingType = (value?: ShiftBookingTypeFilterOption) => {
    setStoredFilter("shiftBookingType", value);
  };

  const setShiftTimeSlot = (value?: ShiftTimeSlotFilterOption) => {
    setStoredFilter("shiftTimeSlot", value);
  };

  const setWorkplaceRating = (value?: WorkplaceRatingFilterOption) => {
    setStoredFilter("workplaceRating", value);
  };

  const setFilters = (filters: Partial<UserFilters>) => {
    const { dates, distance: newDistance, license: newLicense, ...rest } = filters;

    if (isDefined(dates)) {
      setDates(dates);
    }

    if (isDefined(newDistance) || isDefined(newLicense)) {
      setAgentPreferences({
        distance: newDistance ?? distance,
        license: newLicense ?? license,
      });
    }

    if (Object.keys(rest).length > 0) {
      setStoredFilters((storedFilters) => {
        // New filters can have undefined values, which means the filter should
        // be cleared. Using spread operator would override undefined values with
        // what's in storedFilters, so we need to use Object.assign instead.
        // eslint-disable-next-line prefer-object-spread
        return Object.assign({}, storedFilters, rest);
      });
    }
  };

  const appliedFiltersCount = Object.values(storedFilters).filter((value) =>
    isDefined(value)
  ).length;

  const clearFilters = () => {
    clearDates();
    setAgentPreferences({
      distance: DEFAULT_DISTANCE_IN_MILES_FILTER,
      license: worker.qualification ?? "",
    });
    clearStoredFilters();
  };

  return {
    appliedFiltersCount,
    clearFilters,
    setFilters,
    /**
     * Date strings in the format of "yyyy-MM-dd"
     */
    dates,
    setDates,
    clearDates,
    distance,
    license,
    setDistance,
    setLicense,
    ...storedFilters,
    setWorkplaceRating,
    setShiftBookingType,
    setShiftTimeSlot,
  };
}

export const [ShiftDiscoveryUserFiltersProvider, useShiftDiscoveryUserFiltersContext] = constate(
  useShiftDiscoveryUserFilters
);
